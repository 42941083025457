/* eslint-disable no-console */
import {Controller} from '@hotwired/stimulus';

export default class OrderWidgetController extends Controller {
  static targets = ['previewHazardListContainer'];
  previewHazardListContainerTarget!: HTMLElement;

  fetchHazardData(lat?: number, lng?: number) {
    const url = '/orders/fetch_hazard_data';
    const params = new URLSearchParams({lat: lat?.toString() || '', lng: lng?.toString() || ''});

    fetch(`${url}?${params}`, {method: 'GET'})
      .then((response) => response.json())
      .then((data) => this.handleFetchResponse(data))
      .catch((error) => console.error('AJAX request failed:', error));
  }

  handleFetchResponse(data: any) {
    if (data.error) {
      console.error('Failed to fetch hazard data:', data.error);
      this.previewHazardListContainerTarget.innerHTML = data.html;
    } else {
      this.previewHazardListContainerTarget.innerHTML = data.html;
    }
  }
}
